import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import ContactSection from 'components/ContactSection/ContactSection';

const ContactUs = () => {
  return (
    <Layout contactForm={false}>
      <Seo title="Contact us" />
      <section className="hero-wrapper">
        <div className="hero">
          <div className="hero__content">
            <h1 className="hero__title">
              <Trans>Contact us @ YanchWare</Trans>
            </h1>
            <p className="hero__text">
              <Trans>
                Whether you're interested in learning more about our products, have questions about our services,
                or are looking to join our dynamic team, we’re here to help. Fill out the form below, and one of our
                representatives will get back to you as soon as possible.
              </Trans>
            </p>
          </div>
          <Image
            name="contact-us-page/contact-us-hero"
            className="hero__image"
          />
        </div>
      </section>
      <section className="container">
        <h2>
          <Trans>Connect With Us:</Trans>
        </h2>
        <p>
          <Trans>
            Fill out the form below, and let’s start a conversation. Your journey with YanchWare begins here!
          </Trans>
        </p>
      </section>
      <ContactSection/>
    </Layout>
  );
};

export default ContactUs;
