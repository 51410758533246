import './../../scss/components/ContactSection.scss';

import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import superagent from 'superagent';
import { load } from 'recaptcha-v3';
import emailValidationRegex from 'utils/emailValidationRegex';

const ContactForm = () => {
  // TODO: move to config file
  const emailEndPoint = 'https://europe-west3-yanchware.cloudfunctions.net/yanchwareContacts';

  const [submitSuccessState, setSubmitSuccessState] = useState(false);
  const [submitFailedState, setSubmitFailedState] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const onSubmit = async (emailDeliveryRequest) => {
    setSubmitButtonDisabled(true);

    const recaptcha = await load('6LeoaccZAAAAAH5_dOmimvOwM3rF62dh25UPKd6Z');
    const token = await recaptcha.execute('submit');
    emailDeliveryRequest.token = token;
    if (window) {
      return superagent
        .post(emailEndPoint)
        .send(emailDeliveryRequest)
        .set('Content-Type', 'application/json')
        .then((response) => {
          if (response.status === 200) {
            setSubmitSuccessState(true);
          }
        })
        .catch((err) => {
          setSubmitFailedState(true);
        });
    }
  };

  if (submitSuccessState) {
    return (
      <div className="contact-section__form">
        <div className="contact-section__form-sent-successfully tac">
          <h3>
            <Trans>Thank you for reaching out!</Trans>
          </h3>
          <p>
            <Trans>You will get a response as soon as possible!</Trans>
          </p>
        </div>
      </div>
    );
  }

  return (
    <form noValidate className="contact-section__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="contact-section__row">
        <label className="contact-section__label" htmlFor="cf-name">
          <Trans>First Name*</Trans>
        </label>
        <input
          id="cf-name"
          name="cf-name"
          className={classNames('contact-section__input', {
            'contact-section__input--error': errors.name,
          })}
          type="text"
          aria-invalid={errors.name ? 'true' : 'false'}
          {...register('name', {
            required: {
              value: Boolean,
              message: 'First Name is required.',
            },
          })}
        />
        <div
          role="alert"
          className={classNames('contact-section__input-error', {
            'contact-section__input-error--show': errors.name,
          })}
        >
          {errors.name?.message}
        </div>
      </div>

      <div className="contact-section__row">
        <label className="contact-section__label" htmlFor="cf-s-name">
          <Trans>Last Name*</Trans>
        </label>
        <input
          id="cf-s-name"
          name="cf-s-name"
          className={classNames('contact-section__input', {
            'contact-section__input--error': errors.surname,
          })}
          type="text"
          aria-invalid={errors.surname ? 'true' : 'false'}
          {...register('surname', {
            required: {
              value: Boolean,
              message: 'Last Name is required.',
            },
          })}
        />
        <div
          role="alert"
          className={classNames('contact-section__input-error', {
            'contact-section__input-error--show': errors.surname,
          })}
        >
          {errors.surname?.message}
        </div>
      </div>

      <div className="contact-section__row">
        <label className="contact-section__label" htmlFor="cf-email">
          <Trans>E-mail*</Trans>
        </label>
        <input
          id="cf-email"
          name="cf-email"
          className={classNames('contact-section__input', {
            'contact-section__input--error': errors.email,
          })}
          type="email"
          aria-invalid={errors.email ? 'true' : 'false'}
          {...register('email', {
            pattern: {
              value: emailValidationRegex,
              message: 'Email is invalid.',
            },
            required: {
              value: Boolean,
              message: 'Email is required.',
            },
          })}
        />
        <div
          role="alert"
          className={classNames('contact-section__input-error', {
            'contact-section__input-error--show': errors.email,
          })}
        >
          {errors.email?.message}
        </div>
      </div>

      <div className="contact-section__row">
        <label className="contact-section__label" htmlFor="cf-message">
          <Trans>Message*</Trans>
        </label>
        <textarea
          id="cf-message"
          name="cf-message"
          rows="5"
          className={classNames('contact-section__textarea', {
            'contact-section__textarea--error': errors.message,
          })}
          aria-invalid={errors.message ? 'true' : 'false'}
          {...register('message', {
            required: {
              value: Boolean,
              message: 'Message is required.',
            },
          })}
        />
        <div
          role="alert"
          className={classNames('contact-section__input-error', {
            'contact-section__input-error--show': errors.message,
          })}
        >
          {errors.message?.message}
        </div>
      </div>

      <input
        disabled={submitButtonDisabled}
        type="submit"
        value={submitButtonDisabled ? 'Sending...' : 'Send'}
        className="contact-section__button"
      />

      {submitFailedState && (
        <div className="contact-section__form-error">
          <Trans>
            Oh no! Something went wrong! <br />
            Please refresh the page and try again.
          </Trans>
        </div>
      )}
    </form>
  );
};

const ContactSection = () => (
  <section className="contact-section">
    <div className="contact-section__content">
      <ContactForm />
    </div>
    <div className="contact-section__info">
      <h2 className="contact-section__title">
        <Trans>GET IN TOUCH</Trans>
      </h2>
      <p className="contact-section__email">hello@yanchware.com</p>
      <p className="contact-section__text">
        <strong>YanchWare Danmark</strong> <br />
        Mågevej 11 <br />
        9640, Farsø <br />
        +45 23 86 53 72
      </p>
      <p className="contact-section__text">
        <strong>YanchWare Italia</strong> <br />
        Via A. M. Mazzei 21 <br />
        95030, Nicolosi (CT) <br />
        +39 348 755 1687
      </p>
    </div>
  </section>
);

export default ContactSection;
